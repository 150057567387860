import { useEffect, useState, useRef, CSSProperties, RefObject } from "react"
import useScrollDirection from "./useScrollDirection"
import useVisibility from "./useVisibility"

const useFixedHeader = <T extends HTMLElement>(): [
  RefObject<T>,
  CSSProperties
] => {
  const [ref, visibility, top, height] = useVisibility<T>()
  const [style, setStyle] = useState<CSSProperties>({
    top: "0px",
    position: "absolute",
  })

  const [up, scrollY] = useScrollDirection()
  const [localUp, setLocalUp] = useState(up)
  const [fixed, setFixed] = useState(false)

  useEffect(() => {
    if (up) {
      if (visibility) {
        if (top > 0) {
          setStyle(style => ({
            ...style,
            top: `0px`,
          }))
          setFixed(true)
        }
      } else {
        setStyle(style => ({
          ...style,
          top: `${scrollY - height}px`,
        }))
      }
    } else {
      setFixed(false)
    }
    setLocalUp(up)
  }, [visibility, up, top])

  useEffect(() => {
    if (scrollY < 0) {
      setFixed(true)
    }
  }, [scrollY])

  useEffect(() => {
    setFixed(true)
  }, [])

  useEffect(() => {
    setStyle(style => ({
      ...style,
      top: fixed ? "0px" : `${scrollY}px`,
      position: fixed ? "fixed" : "absolute",
    }))
  }, [fixed])

  return [ref, style]
}

export default useFixedHeader
