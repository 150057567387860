import { useEffect, useRef, useState, RefObject } from "react"

const useVisibility = <T extends HTMLElement>(): [
  RefObject<T>,
  boolean | undefined,
  number,
  number
] => {
  const ref = useRef<T>(null)
  const [visibility, setVisibility] = useState<boolean | undefined>(undefined)
  const [top, setTop] = useState(0)
  const [height, setHeight] = useState(0)
  useEffect(() => {
    const handleScroll = () => {
      const rect = ref.current?.getBoundingClientRect()

      if (!rect) {
        setVisibility(undefined)
      } else {
        const { top, bottom, height } = rect
        setTop(top)
        setHeight(height)

        setVisibility(top < window.innerHeight && bottom > 0)
      }
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
    handleScroll()
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return [ref, visibility, top, height]
}

export default useVisibility
