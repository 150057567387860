import { createGlobalStyle } from "styled-components"
import "./fonts/SDGretaSansKRjBlk.css"

const GlobalStyle = createGlobalStyle`
  * {
  
  margin: 0;

  font-family: Sandoll Greta Sans Beta;
  font-style: normal;
  font-weight: 400;
  

  line-height: 1.57;

  -webkit-user-drag: none;
  }

  .lg {
    font-size: 72px;
  }
  .md {
    font-size: 64px;
  }
  .sm {
    font-size: 36px;
  }
  .md-mobile {
    font-size: 64px;
  }
  .sm-mobile {
    font-size: 36px;
  }
  
  html {
    font-size: 36px;
    overflow-x: hidden;
  }

  .header-container {
    font-size: 36px;
    height: 126px;
    padding: 18px 1rem 0;
  }
  header {
    height: 126px;
  }
  .header-background {
    height:108px;
  }
  .body {
    overflow-x: hidden;
    padding: 108px 1rem 36px 1rem;
    width: calc(100vw - 2rem);
  }


.symbol {
  font-size: 0.85em;
  vertical-align: 0.07em;
}

p {
  color: #4400CC;
  font-size: inherit;
  word-break: keep-all;
}

span {
  font-size: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

.caption {
  width: 60%;
  text-align: center;
  margin : 0 auto;
  padding-top: 0.5em;
}

.iframe-container {
  width: 70vw;
  margin: 0 auto;
  position: relative;

  height: 0;
  padding-bottom: 39.375vw;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;        
}

.img-container {
  width: 70vw;
  margin: 0 auto;
}

/**
 * iPad landscpae
 * Bootstrap lg
 */

@media only screen and (max-width: 1200px) {
  .lg {
    font-size: 48px;
  }
  .md {
    font-size: 44px;
  }
  .sm {
    font-size: 26px;
  }
  html {
    font-size: 26px;
  }

  .md-mobile {
    font-size: 44px;
  }
  .sm-mobile {
    font-size: 26px;
  }
  
  .header-container {
    font-size: 26px;
    height: 91px;
    padding: 13px 1rem 0;
  }

  .header-background {
    height:91px;
  }
  header {
    height: 78px;
  }
  .body {
    overflow-x: hidden;
    padding: 78px 1rem 26px 1rem;
    width: calc(100vw - 2rem);
  }
}

/**
 * iPad Portrait,
 * Bootstrap md
 */

@media only screen and (max-width: 992px) {
  .lg {
    font-size: 36px;
  }
  .md {
    font-size: 32px;
  }
  .sm {
    font-size: 18px;
  }
  html {
    font-size: 18px;
  }

  .md-mobile {
    font-size: 32px;
  }
  .sm-mobile {
    font-size: 24px;
  }


  .header-container {
    font-size: 24px;

    height: 84px;
    padding: 12px 1rem 0;
  }
  .header-background {
    height:84px;
  }
  header {
    height: 72px;
  }
  .body {
    overflow-x: hidden;
    padding: 72px 1rem 24px 1rem;
    width: calc(100vw - 2rem);
  }
}

@media only screen and (max-width: 992px) and (orientation: portrait) {

  .iframe-container {
  width: calc(100vw - 2rem);
  margin: 0 auto;
  position: relative;

  height: 0;
  padding-bottom: calc((100vw - 2rem) * 0.5625);
}

.img-container {
  width: calc(100vw - 2rem);
  margin: 0 auto;
}


}


/**
 * iPhone landscape,
 * Bootstrap sm
 */

@media only screen and (max-width: 768px) {
  .lg {
    font-size: 28px;
  }
  .md {
    font-size: 24px;
  }
  .sm {
    font-size: 16px;
  }
  html {
    font-size: 16px;
  }

  .md-mobile {
    font-size: 32px;
  }
  .sm-mobile {
    font-size: 20px;
  }

  .header-container {
    font-size: 20px;

    height: 70px;
    padding: 10px 1rem 0;
  }
  .header-background {
    height:70px;
  }
  header {
    height: 60px;
  }
  .body {
    overflow-x: hidden;
    padding: 60px 1rem 20px 1rem;
    width: calc(100vw - 2rem);
  }
}

/**
 * iPhone portrait,
 * Bootstrap xs
 */
@media only screen and (max-width: 576px) {
  .lg {
    font-size: 22px;
  }
  .md {
    font-size: 20px;
  }
  .sm {
    font-size: 14px;
  }
  html {
    font-size: 14px;
  }
  .caption {
    width: 90%;
  }

  .md-mobile {
    font-size: 30px;
  }
  .sm-mobile {
    font-size: 18px;
  }
}

table tr td {
  border-collapse: collapse !important;
  border-spacing: 0;
  padding: 0;
  border: none;
  margin: 0;
  text-indent: 0px;
  
}

table {
  font-size: inherit;
}

td {
  margin: 0;
  padding: 0;
}

body {
  top:0;
  bottom:0;
  overflow:scroll;
}
`

export default GlobalStyle
