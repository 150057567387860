import { useLayoutEffect, useState, useRef } from "react"

const useScrollDirection = (): [boolean, number] => {
  const [pre, setPre] = useState<number | null>(null)
  const preRef = useRef(pre)
  const [up, setUp] = useState(false)

  useLayoutEffect(() => {
    const listener = () => {
      const now = window.scrollY
      if (preRef.current === null) {
        preRef.current = now
      }
      if (now > preRef.current) {
        setUp(false)
      } else {
        setUp(true)
      }
      preRef.current = now
    }
    window.addEventListener("scroll", listener)
    listener()
    return () => {
      window.removeEventListener("scroll", listener)
      console.log("event listener refershed")
    }
  }, [])
  return [up, preRef.current]
}

export default useScrollDirection
