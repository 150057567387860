import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import useFixedHeader from "../hooks/useFixedHeader"

const Background = styled.div`
  position: absolute;

  width: 100%;
  z-index: 500;

  top: 0;

  background: linear-gradient(#fff, #fff 70%, rgba(255, 255, 255, 0));

  pointer-events: none;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: inherit;
  vertical-align: top;
  color: #4400cc;
  @media (hover: hover) {
    &:hover {
      color: #e1ff00;
    }
  }
`

interface HeaderProps {
  siteTitle: string
}

const Header = ({ siteTitle }: HeaderProps) => {
  const [ref, style] = useFixedHeader<HTMLElement>()

  return (
    <header
      style={{
        width: "100vw",
        zIndex: 998,
        pointerEvents: "none",
        ...style,
      }}
      ref={ref}
    >
      <div
        className="header-container"
        style={{
          margin: 0,
          position: "relative",
          width: "calc(100% - 2rem)",
          overflow: "hidden",

          top: 0,
          zIndex: 600,

          pointerEvents: "none",
        }}
      >
        <div
          className="header-title"
          style={{
            position: "relative",
            display: "inline-block",
            float: "left",
            pointerEvents: "auto",
          }}
        >
          <StyledLink to="/">{siteTitle}</StyledLink>
        </div>
      </div>
      <Background className="header-background" />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
