import React from "react"

import styled from "styled-components"
import { Link } from "gatsby"
import useFrameSize from "../../hooks/useFrameSize"

export const toWbr = (str: string) =>
  str.split("\u200B").reduceRight(
    (prev, curr) => (
      <>
        <span style={{ whiteSpace: "nowrap" }}>{curr}</span>
        <wbr />
        <span style={{ whiteSpace: "nowrap" }}>{prev}</span>
      </>
    ),
    <></>
  )

type AnchorProps = { underline: boolean; desktopOnly?: boolean }
export const StyledAnchor = styled.a`
  color: #4400cc;
  @media (hover: hover) {
    &:hover {
      color: #e1ff00;
      border-bottom: ${({ underline }: AnchorProps) =>
        underline ? "solid #E1FF00 0.16em" : "none"};
    }
  }

  border-bottom: ${({ underline }: AnchorProps) =>
    underline ? "solid #4400CC 0.16em" : "none"};

  padding-bottom: 0.1em;

  @media only screen and (max-width: 992px) and (orientation: portrait) {
    ${props => (props.desktopOnly ? "display: none;" : null)}
  }
`

export const StyledRow = styled.tr`
  color: #4400cc;

  @media (hover: hover) {
    &:hover {
      color: #e1ff00;
    }
  }

  > td {
    vertical-align: top;
    > div {
      vertical-align: top;
    }
  }
`

type DivProps = { center?: boolean; desktopOnly?: boolean }
export const StyledDiv = styled.div`
  width: calc(100% - 0.5em);
  padding-right: 0.5em;

  word-break: keep-all;
  text-align: ${(props: DivProps) => (props.center ? "center" : "left")};
  margin: 0;

  padding-left: ${(props: DivProps) => (props.center ? "-0.75em" : 0)};

  @media only screen and (max-width: 576px) and (orientation: portrait) {
    ${props => (props.desktopOnly ? "display: none;" : null)}
  }
`

type TdProps = { width: number }
export const StyledTd = styled.td`
  width: ${({ width }: TdProps) => (width ? `${width * 100}%` : "")};
`
export const StyledIndex = styled.td`
  width: 10%;
  @media only screen and (max-width: 576px) and (orientation: portrait) {
    width: 4em;
  }
`
export const StyledTitle = styled.td`
  width: 25%;
  @media only screen and (max-width: 576px) and (orientation: portrait) {
    width: 60vw;
  }
`

interface MenuItemProps {
  index: {
    payload: string
    class?: string
  }
  center?: boolean
  title: string
  body: string
  linkTo: string
}
const MenuItem = ({ index, center, title, body, linkTo }: MenuItemProps) => {
  const [width, height] = useFrameSize()

  return (
    <StyledRow>
      <StyledIndex>
        <StyledDiv center={center} className="menu-item-index md-mobile">
          <Link to={linkTo} className={index.class}>
            {index.payload}
          </Link>
        </StyledDiv>
      </StyledIndex>
      <StyledTitle>
        <StyledDiv className="menu-item-title md-mobile">
          <Link to={linkTo}>
            {width < height && width <= 576 ? (
              title.includes("오래오") ? (
                "오래오스튜디오"
              ) : title.includes("로리스") ? (
                <>
                  이노을 × <wbr />
                  <span style={{ whiteSpace: "nowrap" }}>로리스 올리비에</span>
                </>
              ) : (
                title
              )
            ) : (
              toWbr(title)
            )}
          </Link>
        </StyledDiv>
      </StyledTitle>
      <StyledTd width={0.65}>
        <StyledDiv desktopOnly className="menu-item-body md-mobile">
          <Link to={linkTo}>{toWbr(body)}</Link>
        </StyledDiv>
      </StyledTd>
    </StyledRow>
  )
}

export default MenuItem
