/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode } from "react"

import Header from "./header"
import Navigator from "./navigator"

import GlobalStyle from "../styles/GlobalStyle"
import useFrameSize from "../hooks/useFrameSize"

interface LayoutProps {
  children: ReactNode
  prev?: {
    name: string
    link: string
  }
  next?: {
    name: string
    link: string
  }
  title?: string
}

const Layout = ({ children, prev, next, title }: LayoutProps) => {
  const [width, height] = useFrameSize()
  return (
    <>
      <GlobalStyle />
      <Header
        siteTitle={
          width < height && width < 768
            ? "네오-트라이브2020"
            : "네오-트라이브2020: 사회가 잠시 멀어졌을 때"
        }
      />

      <div
        style={{
          position: "absolute",
          margin: `0 auto 0`,

          left: 0,
          top: 0,
        }}
        className="body md"
      >
        <nav>
          {title ? (
            <>
              <Navigator prev={prev} next={next} title={title} />
              <br />
            </>
          ) : null}
        </nav>

        <main>{children}</main>

        <br />

        <footer>
          {next ? <Navigator prev={prev} next={next} title="" /> : null}
        </footer>
      </div>
    </>
  )
}

export default Layout
