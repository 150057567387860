import React, { useLayoutEffect, useState } from "react"

import styled from "styled-components"
import { Link } from "gatsby"
import { toWbr } from "./landing/MenuItem"
import useFrameSize from "../hooks/useFrameSize"

interface NavigatorProps {
  title: string
  prev?: {
    name: string
    link: string
  }
  next?: {
    name: string
    link: string
  }
}

const StyledLink = styled(Link)`
  color: #4400cc;
  vertical-align: text-top;

  @media (hover: hover) {
    &:hover {
      color: #e1ff00;
    }
  }
`

const StyledTitle = styled.div`
  color: #4400cc;
  text-align: center;
  margin: 0 auto;
  padding-left: -100%;
  padding-right: -100%;
  width: 60%;
  @media only screen and (max-width: 992px) and (orientation: portrait) {
    width: 80%;
  }
`

const Navigator = ({ title, prev, next }: NavigatorProps) => {
  const [width, height] = useFrameSize()
  const [linkPrev, setLinkPrev] = useState("")
  const [linkNext, setLinkNext] = useState("")

  useLayoutEffect(() => {
    if (width <= 576 && width < height) {
      setLinkPrev("\u3000\u3000\u3000")
      setLinkNext("\u3000\u3000\u3000")
    } else {
      setLinkPrev(prev?.name)
      setLinkNext(next?.name)
    }
  }, [width, height])

  return (
    <div style={{ width: "100%" }}>
      {prev ? (
        <div
          className="prev sm-mobile"
          style={{
            float: "left",
            maxWidth: "10%",
            overflow: "visible",
            wordBreak: "keep-all",
            whiteSpace: "nowrap",
            zIndex: 500,
          }}
        >
          <StyledLink
            to={prev.link}
            className="prev-link sm-mobile"
          >{`〈 ${linkPrev}`}</StyledLink>
        </div>
      ) : null}
      {next ? (
        <div
          className="next sm-mobile"
          style={{
            float: "right",
            width: "10%",
            maxWidth: "10%",
            overflow: "visible",
            wordBreak: "keep-all",
            whiteSpace: "nowrap",
            zIndex: 500,
          }}
        >
          <span style={{ position: "absolute", right: "1rem" }}>
            <StyledLink
              to={next.link}
              className="next-link sm-mobile"
            >{`${linkNext} 〉`}</StyledLink>
          </span>
        </div>
      ) : null}
      <StyledTitle className="title lg">
        {title.split("\n").reduceRight(
          (prev, curr) => (
            <>
              {toWbr(curr)}
              <br />
              {prev}
            </>
          ),
          <></>
        )}
      </StyledTitle>
    </div>
  )
}

export default Navigator
